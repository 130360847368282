// AES256 Encryption Key
module.exports.base = {
  key: "pasteezbytheramencoders",
  passphrase: "!pasteezpass",
};

// Default Example data
module.exports.examples = {
  codeExample: `const number = parseInt(prompt('Enter the number of terms: '));
let n1 = 0, n2 = 1, nextTerm;
console.log('Fibonacci Series:');
for (let i = 1; i <= number; i++) {
  console.log(n1);
  nextTerm = n1 + n2;
  n1 = n2;
   n2 = nextTerm;
}`,
  titleExample: "title",
  syntaxStyleExample: "agate",
  langExample: "javascript",
  defaultFrameBG: "linear-gradient(90deg, #d0f4ff 0%, #2b8389 100%)",
};

// Array of all the languages available
module.exports.languages = [
  "1C",
  "ABNF",
  "Apache",
  "Arduino",
  "Autohotkey",
  "Bash",
  "BNF",
  "Brainfuck",
  "C",
  "Clojure",
  "Cmake",
  "CoffeeScript",
  "Coq",
  "Cpp",
  "Csharp",
  "Dart",
  "Django",
  "Dockerfile",
  "DOS",
  "DSConfig",
  "Dust",
  "Elixir",
  "Elm",
  "Erlang",
  "Fortran",
  "Fsharp",
  "Go",
  "Gradle",
  "Https",
  "Java",
  "Javascript",
  "Json",
  "Kotlit",
  "LaTeX",
  "Leaf",
  "Less",
  "Lisp",
  "LiveScript",
  "Lua",
  "Makefile",
  "Markdown",
  "Matlab",
  "Maven",
  "Mercury",
  "Monkey",
  "Nginx",
  "Nim",
  "Nix",
  "NSIS",
  "ObjectiveC",
  "OCaml",
  "Octave",
  "Perl",
  "PHP",
  "Plaintext",
  "Powershell",
  "Pug",
  "Puppet",
  "Python",
  "R",
  "Ruby",
  "Rust",
  "Sas",
  "Scheme",
  "SCSS",
  "Shell",
  "SQL",
  "Swift",
  "TCL",
  "Typescript",
  "Vbscript",
  "Vim",
  "Wasm",
  "XML",
  "YAML",
];

//object of all the syntax highlighting styles with their corresponding css file path
module.exports.syntaxStyles = {
  "a11y-dark": "./syntaxStyles/a11y-dark.css",
  "a11y-light": "./syntaxStyles/a11y-light.css",
  agate: "./syntaxStyles/agate.css",
  "an-old-hope": "./syntaxStyles/an-old-hope.css",
  androidstudio: "./syntaxStyles/androidstudio.css",
  "arduino-light": "./syntaxStyles/arduino-light.css",
  arta: "./syntaxStyles/arta.css",
  ascetic: "./syntaxStyles/ascetic.css",
  "atom-one-dark-reasonable": "./syntaxStyles/atom-one-dark-reasonable.css",
  "atom-one-dark": "./syntaxStyles/atom-one-dark.css",
  "atom-one-light": "./syntaxStyles/atom-one-light.css",
  "brown-paper": "./syntaxStyles/brown-paper.css",
  "codepen-embed": "./syntaxStyles/codepen-embed.css",
  "color-brewer": "./syntaxStyles/color-brewer.css",
  dark: "./syntaxStyles/dark.css",
  default: "./syntaxStyles/default.css",
  devibeans: "./syntaxStyles/devibeans.css",
  docco: "./syntaxStyles/docco.css",
  far: "./syntaxStyles/far.css",
  foundation: "./syntaxStyles/foundation.css",
  "github-dark-dimmed": "./syntaxStyles/github-dark-dimmed.css",
  "github-dark": "./syntaxStyles/github-dark.css",
  github: "./syntaxStyles/github.css",
  gml: "./syntaxStyles/gml.css",
  googlecode: "./syntaxStyles/googlecode.css",
  "gradient-dark": "./syntaxStyles/gradient-dark.css",
  "gradient-light": "./syntaxStyles/gradient-light.css",
  grayscale: "./syntaxStyles/grayscale.css",
  hybrid: "./syntaxStyles/hybrid.css",
  idea: "./syntaxStyles/idea.css",
  "ir-black": "./syntaxStyles/ir-black.css",
  "isbl-editor-dark": "./syntaxStyles/isbl-editor-dark.css",
  "isbl-editor-light": "./syntaxStyles/isbl-editor-light.css",
  "kimbie-dark": "./syntaxStyles/kimbie-dark.css",
  "kimbie-light": "./syntaxStyles/kimbie-light.css",
  lightfair: "./syntaxStyles/lightfair.css",
  lioshi: "./syntaxStyles/lioshi.css",
  magula: "./syntaxStyles/magula.css",
  "mono-blue": "./syntaxStyles/mono-blue.css",
  "monokai-sublime": "./syntaxStyles/monokai-sublime.css",
  monokai: "./syntaxStyles/monokai.css",
  "night-owl": "./syntaxStyles/night-owl.css",
  "nnfx-dark": "./syntaxStyles/nnfx-dark.css",
  "nnfx-light": "./syntaxStyles/nnfx-light.css",
  nord: "./syntaxStyles/nord.css",
  obsidian: "./syntaxStyles/obsidian.css",
  "paraiso-dark": "./syntaxStyles/paraiso-dark.css",
  "paraiso-light": "./syntaxStyles/paraiso-light.css",
  pojoaque: "./syntaxStyles/pojoaque.css",
  purebasic: "./syntaxStyles/purebasic.css",
  "qtcreator-dark": "./syntaxStyles/qtcreator-dark.css",
  "qtcreator-light": "./syntaxStyles/qtcreator-light.css",
  rainbow: "./syntaxStyles/rainbow.css",
  routeros: "./syntaxStyles/routeros.css",
  "school-book": "./syntaxStyles/school-book.css",
  "shades-of-purple": "./syntaxStyles/shades-of-purple.css",
  srcery: "./syntaxStyles/srcery.css",
  "stackoverflow-dark": "./syntaxStyles/stackoverflow-dark.css",
  "stackoverflow-light": "./syntaxStyles/stackoverflow-light.css",
  sunburst: "./syntaxStyles/sunburst.css",
  "tomorrow-night-blue": "./syntaxStyles/tomorrow-night-blue.css",
  "tomorrow-night-bright": "./syntaxStyles/tomorrow-night-bright.css",
  vs: "./syntaxStyles/vs.css",
  vs2015: "./syntaxStyles/vs2015.css",
  xcode: "./syntaxStyles/xcode.css",
  xt256: "./syntaxStyles/xt256.css",
};
