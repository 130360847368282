export const POSTS_LIST_REQUEST = "POSTS_LIST_REQUEST";
export const POSTS_LIST_SUCCESS = "POSTS_LIST_SUCCESS";
export const POSTS_LIST_FAIL = "POSTS_LIST_FAIL";
export const POSTS_REMOVEALL_SUCCESS = "POSTS_REMOVEALL_SUCCESS";

export const POSTS_CREATE_REQUEST = "POSTS_CREATE_REQUEST";
export const POSTS_CREATE_SUCCESS = "POSTS_CREATE_SUCCESS";
export const POSTS_CREATE_FAIL = "POSTS_CREATE_FAIL";

export const POSTS_UPDATE_REQUEST = "POSTS_UPDATE_REQUEST";
export const POSTS_UPDATE_SUCCESS = "POSTS_UPDATE_SUCCESS";
export const POSTS_UPDATE_FAIL = "POSTS_UPDATE_FAIL";

export const POSTS_DELETE_REQUEST = "POSTS_DELETE_REQUEST";
export const POSTS_DELETE_SUCCESS = "POSTS_DELETE_SUCCESS";
export const POSTS_DELETE_FAIL = "POSTS_DELETE_FAIL";
