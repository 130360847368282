import React from "react";

export default function Footer() {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-content">
          made by team <b>TheRamenCoders</b>
        </div>
      </div>
    </footer>
  );
}
